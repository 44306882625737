import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class LicitacaoDetalheContrato extends Component {
  static propTypes = {
    contratos: PropTypes.object.isRequired,
    isIntegradoOxy: PropTypes.bool.isRequired
  };

  onViewDetalhes = contrato => {
    const { isIntegradoOxy } = this.props;

    const idOxy = contrato.get('idOxy');
    const entidade = contrato.get('entidade');
    const exercicio = contrato.get('exercicio');
    const codigoContrato = contrato.get('contrato');
    const tipoAto = contrato.get('tipoAto');

    const params = isIntegradoOxy
      ? `id=${idOxy}`
      : `entidade=${entidade}&exercicio=${exercicio}&contrato=${codigoContrato}&tipoAto=${tipoAto}`;

    this.props.history.push(`/contratos/detalhes?${params}`);
  };

  renderContratos = data => {
    const { isIntegradoOxy } = this.props;

    const key = isIntegradoOxy
      ? data.get('idOxy')
      : `${data.get('entidade')}_${data.get('exercicio')}_${data.get(
          'contrato'
        )}_${data.get('numeroContrato')}_${data.get('tipoAto')}`;

    return (
      <TableActions
        ref="row"
        data={data}
        onMore={this.onViewDetalhes}
        key={key}
      >
        <td>{data.get('descricaoTipoAto')}</td>
        <td>{data.get('numeroContrato') + '/' + data.get('exercicio')}</td>
        <td>{data.get('tipoContrato')}</td>
        <td>{data.get('nome')}</td>
        <td>{data.get('situacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { contratos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={contratos.isEmpty()}>
            <tr>
              <th>Tipo Ato</th>
              <th>N° Contrato</th>
              <th>Tipo Contrato</th>
              <th>Contratado</th>
              <th>Situação</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{contratos.map(this.renderContratos)}</tbody>
        </table>
      </div>
    );
  }
}
