import Modal from 'portaltransparencia-common/components/Modal.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import GastoBateriaTable from './GastoBateriaTable.react';

export class GastoBateriaModal extends Component {
  static propTypes = {
    gastosBateria: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    idEntidade: PropTypes.any
  };

  render() {
    const { gastosBateria, show, onClose, idEntidade } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <GastoBateriaTable
            gastosBateria={gastosBateria}
            idEntidade={idEntidade}
          />
        </Modal>
      );
    }

    return null;
  }
}

export default GastoBateriaModal;
