import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export class GastoManutencaoTable extends Component {
  static propTypes = {
    gastosManutencao: PropTypes.object.isRequired,
    idEntidade: PropTypes.any
  };

  renderGastoManutencao = gastoManutencao => {
    const key = `${gastoManutencao.get('numero')}_${gastoManutencao.get(
      'data'
    )}_${gastoManutencao.get('item')}`;
    const { idEntidade } = this.props;

    return (
      <tr key={key}>
        <td>{gastoManutencao.get('numero')}</td>
        <td>{DateUtils.transform(gastoManutencao.get('data'))}</td>
        <td>{`${gastoManutencao.get('centroDestino')} - ${gastoManutencao.get(
          'centroDestinoDescricao'
        )}`}</td>
        <td>
          <LinkToEmpenho
            entidade={idEntidade}
            exercicio={gastoManutencao.get('anoEmpenho')}
            empenho={gastoManutencao.get('empenho')}
          />
        </td>
        <td>{gastoManutencao.get('item')}</td>
        <td className="right">{gastoManutencao.get('kmAnterior')}</td>
        <td className="right">{gastoManutencao.get('kmAtual')}</td>
        <td className="right">{gastoManutencao.get('mediaCombustivel')}</td>
        <td className="right">
          {NumberUtils.format(gastoManutencao.get('quantidade'))}
        </td>
        <td className="right">
          {NumberUtils.format(gastoManutencao.get('valor'))}
        </td>
        <td className="right">
          {NumberUtils.format(gastoManutencao.get('valorTotal'))}
        </td>
      </tr>
    );
  };

  render() {
    const { gastosManutencao } = this.props;

    return (
      <Panel title="Gastos com Manutenção do Veículo" isTable>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={gastosManutencao.isEmpty()}
              texto="Nenhum gasto encontrado."
            >
              <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Centro Consumo</th>
                <th>Empenho</th>
                <th>Ítem</th>
                <th className="right">Km Anterior</th>
                <th className="right">Km</th>
                <th className="right">Média</th>
                <th className="right">Qtde.</th>
                <th className="right">Valor</th>
                <th className="right">Valor Total</th>
              </tr>
            </TableHeader>
            <tbody>{gastosManutencao.map(this.renderGastoManutencao)}</tbody>
            {!gastosManutencao.isEmpty() && (
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td className="right">
                    <i>Total: </i>
                    <SumListField
                      fieldName="valorTotal"
                      list={gastosManutencao}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

export default GastoManutencaoTable;
