import { Map, fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';
import { downloadBasedOnPlatform } from '../utils/handleBrowserDownload.js';

const initialState = fromJS({
  diarios: Map(),
  chave: '',
  mesPublicacao: '',
  anoPublicacao: '',
  numero: '',
  dataInicio: '',
  dataFim: ''
});

function diarioReducer(state = initialState, action) {
  switch (action.type) {
    case types.PESQUISA_DIARIO_START: {
      return startLoad(state);
    }

    case types.FILTRAR_DIARIO: {
      return state.set(action.name, fromJS(action.value));
    }

    case types.LIMPAR_FILTRO: {
      return (state = fromJS({
        diarios: state.get('diarios'),
        chave: '',
        mesPublicacao: '',
        anoPublicacao: '',
        numero: '',
        dataInicio: '',
        dataFim: ''
      }));
    }

    case types.PESQUISA_DIARIO_SUCCESS: {
      return endLoad(state.set('diarios', fromJS(action.payload)));
    }

    case types.DIARIO_URL_DOWNLOAD_SUCCESS: {
      action.payload.text().then(url => {
        downloadBasedOnPlatform(url);
      });
      break;
    }

    case types.COPIAR_DIARIO_URL_SUCCESS: {
      action.payload.text().then(url => navigator.clipboard.writeText(url));
      break;
    }
  }

  return state;
}

export default diarioReducer;
