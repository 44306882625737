import * as actions from './Actions.js';

import AditivoDetalheInformacao from './AditivoDetalheInformacao.react.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Component from 'react-pure-render/component';
import Helmet from 'react-helmet';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils.js';

export class AditivoDetalhe extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    aditivo: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findAditivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findAditivo, findArquivos } = this.props;

    const entidade = getValueFromUrlSearchParams(location, 'entidade');
    const exercicio = getValueFromUrlSearchParams(location, 'exercicio');
    const numero = getValueFromUrlSearchParams(location, 'numero');
    const sequencia = getValueFromUrlSearchParams(location, 'sequencia');
    const tipoAto = getValueFromUrlSearchParams(location, 'tipoAto');
    const numeroContrato = getValueFromUrlSearchParams(
      location,
      'numeroContrato'
    );
    const exercicioContrato = getValueFromUrlSearchParams(
      location,
      'exercicioContrato'
    );

    findAditivo(
      entidade,
      exercicio,
      numero,
      sequencia,
      tipoAto,
      exercicioContrato,
      numeroContrato
    ).then(() => {
      findArquivos(
        entidade,
        exercicio,
        numero,
        sequencia,
        exercicioContrato,
        numeroContrato,
        tipoAto
      );
    });
  }

  render() {
    const { aditivo, downloadArquivo, arquivos } = this.props;

    const arquivosList = arquivos
      .map(item => item.get('arquivoCompras'))
      .filter(item => !!item);

    return (
      <div>
        <Helmet title={`Aditivo - #${aditivo.get('numeroAditivo')}`} />

        <BotaoImprimir />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Aditivo: ${aditivo.get('numeroAditivo')}/${aditivo.get(
            'sequencia'
          )}`}
        />

        <CabecalhoEntidade />

        <AditivoDetalheInformacao aditivo={aditivo} />

        <Panel isTable tableResponsive title="Anexos">
          <Arquivo arquivos={arquivosList} onDownload={downloadArquivo} />
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    aditivo: state.aditivo.get('aditivo'),
    arquivos: state.aditivo.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(AditivoDetalhe);
