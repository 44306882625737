import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class FormDiaFiltro extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    montarFiltro: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    filtroDespesas: PropTypes.string,
    filtroInicial: PropTypes.func,
    onConsulta: PropTypes.func.isRequired,
    onResetDespesas: PropTypes.func
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroDespesas', event);
  };

  onResetFiltro = () => {
    const { resetCrud, onResetDespesas } = this.props;
    resetCrud('filtroDespesas');
    onResetDespesas();
  };

  onConsulta = () => {
    const { onConsulta } = this.props;

    onConsulta();
  };

  render = () => {
    const { filtros } = this.props;

    return (
      <Panel title="Consultar" mobileClose isForm ref="panelFiltroDespesa">
        <form>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataInicial"
                name="dataInicial"
                ref="dataInicial"
                label="Data Inicial"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataInicial')}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataFinal"
                name="dataFinal"
                ref="dataFinal"
                label="Data Final"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataFinal')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={6}>
              <FilterButtons
                idBtnConsulta="btnFiltrar"
                idBtnReset="btnResetFilter"
                on
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };
}
