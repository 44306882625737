import './styles/licitacaoCalendarioStyles.css';

import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';
import { Map, fromJS } from 'immutable';
import React, { Component, createRef } from 'react';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';

import { EventCalendar } from '@elotech/components';
import FilterButtons from '../components/FilterButtons.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { connect } from 'react-redux';
import moment from 'moment';

class LicitacaoAgenda extends Component {
  static propTypes = {
    filtros: PropTypes.object.isRequired,
    loadModalidades: PropTypes.func.isRequired,
    modalidades: PropTypes.object,
    naturezas: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    searchLicitacoes: PropTypes.func.isRequired,
    situacoes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.calendarRef = createRef();
    this.state = {
      initialLoadFinished: false
    };
  }

  componentDidMount() {
    const { loadModalidades, loadNaturezasProcedimento } = this.props;

    loadModalidades();
    loadNaturezasProcedimento();

    if (this.calendarRef.current) {
      const initialFilter = {
        target: {
          name: 'ignoraEntidadeLogada',
          value: true
        }
      };

      this.onChangeFiltro(initialFilter);
    }
  }

  componentDidUpdate() {
    const { initialLoadFinished } = this.state;

    if (!initialLoadFinished) {
      this.onConsultaLicitacoes();
      this.setState({ initialLoadFinished: true });
    }
  }

  onClickEvent = item => {
    const { extendedProps } = item.event;
    const {
      entidade,
      exercicio,
      tipoLicitacao,
      numeroLicitacao
    } = extendedProps;
    window.open(
      `licitacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${numeroLicitacao}`
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroLicitacoes', event);
  };

  onConsultaLicitacoes = event => {
    const { searchLicitacoes } = this.props;

    if (!this?.calendarRef?.current) return;

    searchLicitacoes(this.montaFilter());

    event && event.preventDefault();
    this.refs.panelFiltroLicitacoes &&
      this.refs.panelFiltroLicitacoes.collapse();
  };

  onResetFiltro = () => {
    const { resetCrud, searchLicitacoes } = this.props;
    resetCrud('filtroLicitacoes');
    searchLicitacoes(this.montaFilter());
  };

  formatDates = (start, end) => {
    const formattedStart = moment(
      start,
      'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
    ).format('YYYY-MM-DDTHH:mm:ss');

    const formattedEnd = moment(
      end,
      'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
    ).format('YYYY-MM-DDTHH:mm:ss');

    return { formattedStart, formattedEnd };
  };

  montaFilter = function () {
    const { entidade } = StorageService.getItem('userInfo');
    const { filtros, page } = this.props;

    const calendarApi = this.calendarRef.current.getApi();
    const { start, end } = calendarApi.currentData.dateProfile.activeRange;
    let filter = `entidade=${entidade.id}`;

    filter = addFilterUrl(filter, 'size=999');
    filter = addFilterUrl(filter, 'number=0');

    if (start && end) {
      const { formattedStart, formattedEnd } = this.formatDates(start, end);
      filter = addFilterUrl(filter, 'dataAberturaInicio=' + formattedStart);
      filter = addFilterUrl(filter, 'dataAberturaFim=' + formattedEnd);
    }

    if (this.refs.modalidade && this.refs.modalidade.getValue()) {
      filter = addFilterUrl(
        filter,
        'modalidade=' + this.refs.modalidade.getValue()
      );
    }

    if (this.refs.numeroLicitacao && this.refs.numeroLicitacao.getValue()) {
      filter = addFilterUrl(
        filter,
        'numeroLicitacao=' + this.refs.numeroLicitacao.getValue()
      );
    }

    if (this.refs.numeroProcesso && this.refs.numeroProcesso.getValue()) {
      filter = addFilterUrl(
        filter,
        'numeroProcesso=' + this.refs.numeroProcesso.getValue()
      );
    }
    if (this.refs.situacao && this.refs.situacao.getValue()) {
      if (this.refs.situacao.getValue() != -1) {
        filter = addFilterUrl(
          filter,
          'situacao=' + this.refs.situacao.getValue()
        );
      }
    }

    if (this.refs.objeto && this.refs.objeto.getValue()) {
      filter = addFilterUrl(filter, 'objeto=' + this.refs.objeto.getValue());
    }

    if (filtros.get('natureza')) {
      filter = addFilterUrl(filter, `natureza=${filtros.get('natureza')}`);
    }

    if (this.refs.cnpjCpf && this.refs.cnpjCpf.getValue()) {
      filter = addFilterUrl(filter, 'cnpjCpf=' + this.refs.cnpjCpf.getValue());
    }

    if (filtros.get('ignoraExercicioLogado')) {
      filter = addFilterUrl(
        filter,
        `ignoraExercicioLogado=${filtros.get('ignoraExercicioLogado')}`
      );
    }

    if (filtros.get('ignoraEntidadeLogada')) {
      filter = addFilterUrl(
        filter,
        `ignoraEntidadeLogada=${filtros.get('ignoraEntidadeLogada')}`
      );
    }

    return addPagination(page, filter);
  };

  handleChangeMonth = info => {
    const { start, end } = info || {};
    if (start && end) this.onConsultaLicitacoes();
  };

  renderPlaceholderNatureza = () => {
    const { filtros } = this.props;

    return (
      <option value="">
        {filtros && filtros.get('modalidade')
          ? 'Selecione'
          : 'Selecione uma modalidade'}
      </option>
    );
  };

  renderNaturezas = () => {
    const { filtros, naturezas } = this.props;
    const naturezasPorModalidade = naturezas?.filter(
      n => n.get('tipoLicitacao') == filtros.get('modalidade')
    );

    return (
      naturezasPorModalidade &&
      naturezasPorModalidade.map(data => (
        <option
          key={`${data.get('tipoLicitacao')}_${data.get(
            'naturezaProcedimentoDescricao'
          )}_${data.get('naturezaProcedimento')}`}
          value={data.get('naturezaProcedimentoDescricao')}
        >
          {data.get('naturezaProcedimentoDescricao')}
        </option>
      ))
    );
  };

  renderModalidades = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('id') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  renderSituacao = item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  };

  permiteIgnorarExercicio = () => {
    const { filtros } = this.props;

    return (
      filtros.get('modalidade') ||
      filtros.get('natureza') ||
      filtros.get('numeroProcesso') ||
      filtros.get('numeroLicitacao') ||
      filtros.get('cnpjCpf') ||
      filtros.get('dataAbertura') ||
      filtros.get('situacao') ||
      filtros.get('objeto')
    );
  };

  getCalendarEvents = () => {
    const { page } = this.props;

    if (!page?.get('content')) return [];

    const licitacoesArray = page.get('content').toArray();

    const events =
      licitacoesArray?.map(licitacao => ({
        title: `${licitacao.get('numeroLicitacao')}/${licitacao.get(
          'exercicio'
        )} - ${licitacao.get('descricao')}`,
        date: licitacao.get('dataAbertura'),
        entidade: licitacao.get('entidade'),
        exercicio: licitacao.get('exercicio'),
        tipoLicitacao: licitacao.get('tipoLicitacao'),
        numeroLicitacao: licitacao.get('numeroLicitacao')
      })) || [];

    return events;
  };

  render() {
    const { filtros, situacoes, modalidades } = this.props;
    const events = this.getCalendarEvents();
    return (
      <>
        <Panel
          title="Consultar em Licitações"
          isForm
          mobileClose
          ref="panelFiltroLicitacoes"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="modalidade"
                  name="modalidade"
                  ref="modalidade"
                  label="Modalidade"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('modalidade')}
                >
                  <option value="">Selecione</option>
                  {modalidades && modalidades.map(this.renderModalidades)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="natureza"
                  name="natureza"
                  ref="natureza"
                  label="Natureza"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('natureza')}
                  disabled={!filtros.get('modalidade')}
                >
                  {this.renderPlaceholderNatureza()}
                  {this.renderNaturezas()}
                </Input>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="numeroLicitacao"
                  name="numeroLicitacao"
                  ref="numeroLicitacao"
                  placeholder="Nº da Licitação"
                  label="Nº da Licitação"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroLicitacao')}
                />
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="numeroProcesso"
                  name="numeroProcesso"
                  ref="numeroProcesso"
                  placeholder="Nº da Processo"
                  label="Nº do Processo"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroProcesso')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="situacao"
                  name="situacao"
                  ref="situacao"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('situacao')}
                >
                  <option value="-1">Selecione</option>
                  {situacoes.map(this.renderSituacao)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label
                    className="label"
                    placeholder="Preencha algum filtro para habilitar"
                  ></label>
                  <Input
                    id="ignoraExercicioLogado"
                    label="Ignora Exercício Logado"
                    type="checkbox"
                    ref="ignoraExercicioLogado"
                    name="ignoraExercicioLogado"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('ignoraExercicioLogado')}
                    disabled={!this.permiteIgnorarExercicio()}
                    placeholder="Preencha algum filtro para habilitar"
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <Input
                    id="ignoraEntidadeLogada"
                    label="Ignora Entidade Logada"
                    type="checkbox"
                    ref="ignoraEntidadeLogada"
                    name="ignoraEntidadeLogada"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('ignoraEntidadeLogada')}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="objeto"
                  type="text"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaLicitacoes}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>
        <EventCalendar
          events={events}
          onClickEvent={this.onClickEvent}
          handleChangeMonth={this.handleChangeMonth}
          ref={this.calendarRef}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const situacoes = fromJS([
    'Adjudicada',
    'Aguardando Abertura',
    'Anulada',
    'Descartada',
    'Deserta',
    'Em Andamento',
    'Fracassada',
    'Homologada',
    'Revogada',
    'Suspensa pelo TCE',
    'Suspensa'
  ]);

  return {
    filtros: state.crud.getIn(['filtroLicitacoes', 'currentRecord'], Map({})),
    modalidades: state.licitacao.get('modalidades'),
    naturezas: state.licitacao.get('naturezasProcedimento'),
    page: state.licitacao.get('licitacoes'),
    situacoes
  };
}

const allActions = {
  ...actions,
  resetCrud,
  onChange
};

export default connect(mapStateToProps, allActions)(LicitacaoAgenda);
