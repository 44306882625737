import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { PARAM_GASTOSVEICULOS } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import FormDetailInformacao from './FormDetailInformacao.react.js';
import VeiculoGastosTable from './VeiculoGastosTable.react.js';

export class FormVeiculoDetail extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    gastos: PropTypes.object.isRequired,
    veiculo: PropTypes.object.isRequired,
    loadVeiculoDetails: PropTypes.func.isRequired,
    loadVeiculo: PropTypes.func.isRequired,
    paramGastosVeiculo: PropTypes.string,
    gastosManutencao: PropTypes.object,
    gastosAbastecimento: PropTypes.object,
    gastosBateria: PropTypes.object,
    gastosPneu: PropTypes.object,
    gastosPeca: PropTypes.object,
    loadGastosManutencao: PropTypes.func.isRequired,
    loadGastosAbastecimento: PropTypes.func.isRequired,
    loadGastosBateria: PropTypes.func.isRequired,
    loadGastosPneu: PropTypes.func.isRequired,
    loadGastosPeca: PropTypes.func.isRequired,
    idEntidade: PropTypes.any,
    downloadArquivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { loadVeiculo, loadVeiculoDetails, location } = this.props;

    const veiculo = getValueFromUrlSearchParams(location, 'veiculo');
    const chapa = getValueFromUrlSearchParams(location, 'chapa');
    const entidade = getValueFromUrlSearchParams(location, 'entidade');

    loadVeiculo(veiculo, chapa, entidade).then(
      loadVeiculoDetails(veiculo, entidade)
    );
  }

  render() {
    const {
      gastos,
      paramGastosVeiculo,
      veiculo,
      gastosManutencao,
      gastosAbastecimento,
      gastosBateria,
      gastosPneu,
      gastosPeca,
      loadGastosManutencao,
      loadGastosBateria,
      loadGastosPneu,
      loadGastosPeca,
      idEntidade,
      downloadArquivo,
      loadGastosAbastecimento,
      history
    } = this.props;
    return (
      <div>
        <Helmet title={`Veículos - Placa - ${veiculo.get('placa')}`} />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Placa do Veículo: ${veiculo.get('placa')}`}
        />

        <BotaoImprimir />

        <CabecalhoEntidade />

        <FormDetailInformacao
          veiculo={veiculo}
          idEntidade={idEntidade}
          history={history}
        />

        {(paramGastosVeiculo == '1' || paramGastosVeiculo == '2') && (
          <VeiculoGastosTable
            gastos={gastos}
            veiculo={veiculo}
            gastosManutencao={gastosManutencao}
            gastosAbastecimento={gastosAbastecimento}
            gastosBateria={gastosBateria}
            gastosPneu={gastosPneu}
            gastosPeca={gastosPeca}
            loadGastosManutencao={loadGastosManutencao}
            loadGastosBateria={loadGastosBateria}
            loadGastosPneu={loadGastosPneu}
            loadGastosPeca={loadGastosPeca}
            loadGastosAbastecimento={loadGastosAbastecimento}
            idEntidade={idEntidade}
          />
        )}

        <div className="hidden-print">
          <Panel title="Anexos" expansible={false} isTable tableResponsive>
            <Arquivo
              arquivos={veiculo.get('arquivos')}
              onDownload={downloadArquivo}
            />
          </Panel>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gastos: state.veiculo.get('gastos'),
    veiculo: state.veiculo.get('veiculo'),
    gastosManutencao: state.veiculo.get('gastosManutencao'),
    gastosBateria: state.veiculo.get('gastosBateria'),
    gastosPneu: state.veiculo.get('gastosPneu'),
    gastosPeca: state.veiculo.get('gastosPeca'),
    gastosAbastecimento: state.veiculo.get('gastosAbastecimento'),
    paramGastosVeiculo: ParametroService.getValorParametro(
      state,
      PARAM_GASTOSVEICULOS
    ),
    idEntidade: state.auth.getIn(['userInfo', 'entidade', 'id'])
  };
}
const allActions = {
  ...actions,
  downloadArquivo
};
export default connect(mapStateToProps, allActions)(FormVeiculoDetail);
