import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class LicitacaoDetalheAta extends Component {
  static propTypes = {
    atas: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool.isRequired
  };

  onViewDetalhes = contrato => {
    const { isIntegradoOxy } = this.props;
    const idOxy = contrato.get('idOxy');
    const entidade = contrato.get('entidade');
    const exercicio = contrato.get('exercicio');
    const codigoContrato = contrato.get('ata');

    this.props.history.push(
      '/contratos/detalhes?' +
        (isIntegradoOxy
          ? `id=${idOxy}`
          : `entidade=${entidade}&exercicio=${exercicio}&contrato=${codigoContrato}&tipoAto=2`)
    );
  };

  renderAta = data => {
    const { onDownload } = this.props;
    const key = `${data.get('ata')}-${data.get('tipoAta')}`;
    const isRegistroPreco = data.get('tipoAta') == 'R';
    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data.get('idArquivo') && onDownload}
        onMore={isRegistroPreco ? this.onViewDetalhes : undefined}
      >
        <td>{data.get('descricaoTipoAta')}</td>
        <td>{data.get('numeroAta')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
      </TableActions>
    );
  };

  render() {
    const { atas } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={atas.isEmpty()}>
            <tr>
              <th>Tipo Ata</th>
              <th>Número Ata</th>
              <th>Data</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{atas.map(this.renderAta)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheAta;
