import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import ParametroService from '../parametros/ParametroService.js';
import { PARAM_USAWEBSERVICE_MARINGA } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';

import * as actions from './Actions.js';

export class PensionistaDetalheVencimentosDescontos extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    tipoEvento: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    loadVencimentosDescontosPensionista: PropTypes.func.isRequired,
    vencimentosDescontos: PropTypes.object.isRequired,
    paramMostraRedutor: PropTypes.string.isRequired,
    paramUsaWebServiceMaringa: PropTypes.string,
    paramDesconsideraOutros: PropTypes.string
  };

  componentDidMount() {
    const {
      item,
      tipoEvento,
      loadVencimentosDescontosPensionista
    } = this.props;

    loadVencimentosDescontosPensionista(item, tipoEvento);
  }

  renderVencimentosDescontos = item => {
    const key = item.get('descricao') + '_' + item.get('valor');

    return (
      <tr key={key}>
        <td id="descricao">
          {item.get('descricao') == null ? 'OUTROS' : item.get('descricao')}
        </td>
        <td>
          <NumberFormatter id="valor" value={Math.abs(item.get('valor'))} />
        </td>
      </tr>
    );
  };

  sum(vencimentosDescontos) {
    return vencimentosDescontos
      .map(item => item.get('valor'))
      .reduce((valor1, valor2) => valor1 + valor2, 0);
  }

  getTitulo(tipoEvento) {
    const { paramUsaWebServiceMaringa } = this.props;

    switch (tipoEvento) {
      case 'P':
        return 'Proventos';
      case 'O':
        return 'Outros Proventos';
    }
    return paramUsaWebServiceMaringa == '1'
      ? 'Descontos Obrigatórios'
      : 'Descontos';
  }

  getTotal(tipoEvento, item) {
    switch (tipoEvento) {
      case 'P':
        return item.get('vencimentos');
      case 'O':
        return item.get('outrosProventos');
    }
    return Math.abs(item.get('descontos'));
  }

  render() {
    const {
      onClose,
      vencimentosDescontos,
      tipoEvento,
      item,
      paramMostraRedutor,
      paramDesconsideraOutros
    } = this.props;

    let title = this.getTitulo(tipoEvento);
    let total = this.getTotal(tipoEvento, item);
    let redutor = 0;
    if (
      paramMostraRedutor == 'S' &&
      (tipoEvento == 'P' || tipoEvento == 'D') &&
      item.get('redutorConstitucional') != null
    ) {
      redutor = item.get('redutorConstitucional');
    }
    let sum = this.sum(vencimentosDescontos);
    let outros = Number((total - sum).toFixed(2));

    const getValueTotal = () => {
      if (tipoEvento === 'D') {
        return paramDesconsideraOutros === 'S' ? sum : total;
      }
      return total - redutor;
    };

    return (
      <div className="modal open">
        <div className="container">
          <span className="close close-modal" onClick={onClose}></span>
          <div className="container">
            <div className="panel panel-table striped fancy">
              <h2 className="panel-title">{title}</h2>

              <div className="panel-tabel-outer">
                <table className="panel-table">
                  <TableHeader empty={vencimentosDescontos.isEmpty()}>
                    <tr>
                      <th>Descrição</th>
                      <th>Valor</th>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {vencimentosDescontos.map(this.renderVencimentosDescontos)}

                    {outros > 0 && paramDesconsideraOutros != 'S' && (
                      <tr>
                        <td>OUTROS DESCONTOS</td>
                        {tipoEvento != 'O' && (
                          <td>
                            <NumberFormatter value={outros} />
                          </td>
                        )}
                      </tr>
                    )}
                    {paramMostraRedutor == 'S' && tipoEvento == 'P' && (
                      <tr>
                        <td>REDUTOR CONSTITUCIONAL</td>
                        {tipoEvento != 'O' && (
                          <td>
                            <NumberFormatter
                              id="redutor"
                              value={redutor > 0 ? redutor * -1 : redutor}
                            />
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>

                  {!vencimentosDescontos.isEmpty() && (
                    <tfoot>
                      <tr>
                        <td />
                        {tipoEvento != 'O' && (
                          <td>
                            <i>Total:</i>
                            <NumberFormatter
                              id="valorTotal"
                              value={getValueTotal()}
                            />
                          </td>
                        )}
                        {tipoEvento === 'O' && (
                          <td>
                            <i>Total:</i>
                            <NumberFormatter id="valorTotal" value={total} />
                          </td>
                        )}
                      </tr>
                    </tfoot>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vencimentosDescontos: state.servidorOxy.get('vencimentosDescontos'),
    paramUsaWebServiceMaringa: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    )
  };
}

export default connect(
  mapStateToProps,
  actions
)(PensionistaDetalheVencimentosDescontos);
