import * as types from './Types.js';

const onChangeFilter = (name, value) => {
  return () => ({
    type: types.FILTRAR_DIARIO,
    name: name,
    value: value
  });
};

const onClearFilter = () => {
  return () => ({
    type: types.LIMPAR_FILTRO
  });
};

const findDiariosOficiais = filtro => {
  return ({ fetch }) => ({
    type: types.PESQUISA_DIARIO,
    payload: {
      promise: fetch(`/api/legislacao/diarios-oficiais/publicados?${filtro}`)
    }
  });
};

const urlDownload = id => {
  return ({ fetch }) => ({
    type: types.DIARIO_URL_DOWNLOAD,
    payload: {
      promise: fetch(`/api/legislacao/diarios-oficiais/url-download/${id}`)
    }
  });
};

const copyDiarioUrl = id => {
  return ({ fetch }) => ({
    type: types.COPIAR_DIARIO_URL,
    payload: {
      promise: fetch(`/api/legislacao/diarios-oficiais/url-download/${id}`)
    }
  });
};

const getStartDate = () => {
  return ({ fetch }) => ({
    payload: {
      promise: fetch(`/api/legislacao/diarios-oficiais/get-start-year`)
    }
  });
};

export {
  findDiariosOficiais,
  onChangeFilter,
  onClearFilter,
  urlDownload,
  copyDiarioUrl,
  getStartDate
};
