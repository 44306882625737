import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import { onChange } from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { PARAM_EM_LIQUIDACAO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';

export class FormDespesaNivel extends Component {
  static propTypes = {
    findDespesasPorNivel: PropTypes.func.isRequired,
    despesasNivel: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    paramEmLiquidacoes: PropTypes.string,
    filtros: PropTypes.object,
    addErrorNotification: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    montarFiltro: PropTypes.func,
    page: PropTypes.object
  };

  filtroInicial = () => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    const execucao = true;
    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}&execucao=${execucao}`;

    return filter;
  };

  componentDidMount() {
    const {
      location,
      montarFiltro,
      findDespesasPorNivel,
      onChange
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);
    const { exercicio } = StorageService.getItem('userInfo');

    let filter = '';

    filter = addFilterUrl(filter, `nivel=${1}`);
    filter = addFilterUrl(filter, `nivelPrograma=${1}`);

    filter = montarFiltro(filter);

    if (!params.dataInicial) {
      params.dataInicial = `${exercicio.id}-01-01`;
      filter = addFilterUrl(filter, `dataInicial=${`${params.dataInicial}`}`);
    }

    if (!params.dataFinal) {
      params.dataFinal = `${exercicio.id}-01-31`;
      filter = addFilterUrl(filter, `dataFinal=${`${params.dataFinal}`}`);
    }

    onChange('filtroDespesa', {
      name: 'nivelFiltro',
      value: 1
    });

    onChange('filtroDespesa', {
      name: 'nivelProjeto',
      value: 0
    });

    findDespesasPorNivel(filter);
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { despesasNivel, addErrorNotification } = this.props;

    if (!despesasNivel.isEmpty()) {
      let nivel = this.props.filtros.get('nivelFiltro');

      window.open(
        `${apiURL}/despesapornivel/report?entidade=${entidade.id}&exercicio=${
          exercicio.id
        }&nivel=${nivel}&nivelPrograma=${0}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onChangeFiltro = event => {
    const {
      location,
      montarFiltro,
      onChange,
      filtros,
      findDespesasPorNivel
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);
    const { exercicio } = StorageService.getItem('userInfo');

    let filter = montarFiltro();

    if (!params.dataInicial) {
      params.dataInicial = `${exercicio.id}-01-01`;
    }

    if (!params.dataFinal) {
      params.dataFinal = `${exercicio.id}-01-31`;
    }

    filter = addFilterUrl(filter, `dataInicial=${`${params.dataInicial}`}`);
    filter = addFilterUrl(filter, `dataFinal=${`${params.dataFinal}`}`);

    let nivel = filtros.get('nivelFiltro');
    let projeto = filtros.get('nivelProjeto');

    onChange('filtroDespesa', event);

    if (event.target.name == 'nivelFiltro') {
      nivel = event.target.value;
    } else {
      projeto = event.target.value;
    }

    projeto = !projeto ? 0 : projeto;

    filter = addFilterUrl(filter, `nivel=${nivel}`);
    filter = addFilterUrl(filter, `nivelPrograma=${projeto}`);

    findDespesasPorNivel(filter);
  };

  renderNiveis = data => {
    const { paramEmLiquidacoes, filtros } = this.props;

    return (
      <tr
        ref="row"
        key={`${data.get('programatica')}_${filtros.get(
          'nivelFiltro'
        )}_${data.get('descricao')}`}
      >
        <td>{data.get('programatica')}</td>
        <td>{data.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorPrevisto')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorCreditosAdicionais')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorEmpenhado')} />
        </td>
        {paramEmLiquidacoes == 'S' && (
          <td className="right">
            <NumberFormatter value={data.get('valorEmLiquidacao')} />
          </td>
        )}
        <td className="right">
          <NumberFormatter value={data.get('valorLiquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorPago')} />
        </td>
      </tr>
    );
  };

  render() {
    const { location, despesasNivel, paramEmLiquidacoes, filtros } = this.props;
    return (
      <section>
        <form>
          <PageHeader location={location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <ExportButtonGroup onClick={this.onExporta} visible={true} />
          <div className="last-updated">
            <Row>
              <div
                className="radio-check-group"
                style={{ textAlign: 'center' }}
              >
                <Input
                  label="Órgão"
                  value={1}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 1}
                />
                <Input
                  label="Unidade Orçamentária"
                  value={2}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 2}
                />
                <Input
                  label="Função"
                  value={3}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 3}
                />
                <Input
                  label="Subfunção"
                  value={4}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 4}
                />
                <Input
                  label="Programa"
                  value={5}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 5}
                />
                <Input
                  label="Projeto/Atividade"
                  value={6}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 6}
                />
                <Input
                  label="Categoria"
                  value={7}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 7}
                />
                <Input
                  label="Natureza"
                  value={10}
                  type="radio"
                  name="nivelFiltro"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('nivelFiltro') == 10}
                />
              </div>
            </Row>
            {filtros.get('nivelFiltro') == 6 && (
              <Row>
                <div
                  className="radio-check-group"
                  style={{ textAlign: 'center' }}
                >
                  <Input
                    label="Todos"
                    value={0}
                    type="radio"
                    name="nivelProjeto"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('nivelProjeto') == 0}
                  />
                  <Input
                    label="Projeto"
                    value={1}
                    type="radio"
                    name="nivelProjeto"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('nivelProjeto') == 1}
                  />
                  <Input
                    label="Atividade"
                    value={2}
                    type="radio"
                    name="nivelProjeto"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('nivelProjeto') == 2}
                  />
                  <Input
                    label="Operação"
                    value={3}
                    type="radio"
                    name="nivelProjeto"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('nivelProjeto') == 3}
                  />
                </div>
              </Row>
            )}
          </div>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={despesasNivel.isEmpty()}>
                <tr>
                  <th>Programática</th>
                  <th>Descrição</th>
                  <th className="right">Dotação Inicial</th>
                  <th className="right">Créditos Adicionais</th>
                  <th className="right">Dotação Atualizada</th>
                  <th className="right">Valor Empenhado</th>
                  {paramEmLiquidacoes == 'S' && (
                    <th className="right">Em Liquidação</th>
                  )}
                  <th className="right">Valor Liquidado</th>
                  <th className="right">Valor Pago</th>
                </tr>
              </TableHeader>
              <tbody>{despesasNivel.map(this.renderNiveis)}</tbody>
            </table>
          </div>
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    despesasNivel: state.despesasDetalhe.get('despesasNivel'),
    paramEmLiquidacoes: ParametroService.getValorParametro(
      state,
      PARAM_EM_LIQUIDACAO
    ),
    filtros: state.crud.getIn(['filtroDespesa', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormDespesaNivel);
