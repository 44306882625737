import * as actions from './Actions.js';

import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { Col, Row } from 'react-bootstrap';

import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import Link from 'portaltransparencia-common/components/link/Link.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';

export class Obra extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    situacoes: PropTypes.object.isRequired,
    loadSituacoes: PropTypes.func.isRequired,
    searchObras: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    setIsParalisada: PropTypes.func.isRequired,
    isParalisada: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const { loadSituacoes, page, setIsParalisada } = this.props;

    setIsParalisada(false);
    loadSituacoes().then(this.searchWithPage(page));
  }

  searchWithPage = page => {
    const { searchObras, filtros } = this.props;
    const filter = this.montaFilter(page, filtros);
    searchObras(filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroObras', event);
  };

  limparFiltros = () => {
    const { setIsParalisada, resetCrud, searchObras } = this.props;
    const newIsParalisada =
      this.refs.tabs?.getActiveTabName() === 'obrasParalisadas';
    setIsParalisada(newIsParalisada);
    resetCrud('filtroObras');
    searchObras(this.montaFilter());
  };

  showContrato = data => {
    const { isIntegradoOxy, entidadeLogada } = this.props;

    const idOxy = data.get('idOxyContrato');
    const entidade = entidadeLogada;
    const contrato = data.get('contrato');
    const exercicioContrato = data.get('exercicioContrato');
    const tipoAto = data.get('tipoAto');

    const params = isIntegradoOxy
      ? `id=${idOxy}`
      : `entidade=${entidade}&exercicio=${exercicioContrato}&contrato=${contrato}&tipoAto=${tipoAto}`;

    this.props.history.push(`/contratos/detalhes?${params}`);
  };

  addFilter = function (actualFilter, filterAnd) {
    let filterOperator = '';
    if (actualFilter !== '') {
      filterOperator = '&';
    }
    return actualFilter + filterOperator + filterAnd;
  };

  montaFilter = function (page, filtros) {
    const { entidade } = StorageService.getItem('userInfo');

    let filter = `entidade=${entidade.id}`;

    if (filtros?.get('ordemServico')) {
      filter = this.addFilter(
        filter,
        `&exercicio=${filtros.get('ordemServico')}`
      );
    }

    if (filtros?.get('descricaoBem')) {
      filter = this.addFilter(
        filter,
        `descricaoBem=${filtros.get('descricaoBem')}`
      );
    }
    if (filtros?.get('descricaoObra')) {
      filter = this.addFilter(
        filter,
        `descricaoObra=${filtros.get('descricaoObra')}`
      );
    }

    if (this.refs.tabs?.getActiveTabName() === 'obrasParalisadas') {
      filter = this.addFilter(filter, 'situacao=2');
    } else if (filtros?.get('situacao')) {
      filter = this.addFilter(filter, `situacao=${filtros.get('situacao')}`);
    }

    if (filtros?.get('empresa')) {
      filter = this.addFilter(filter, `empresa=${filtros.get('empresa')}`);
    }
    if (filtros?.get('numeroIntervencao')) {
      filter = this.addFilter(
        filter,
        `idOrdemServico=${filtros.get('numeroIntervencao')}`
      );
    }
    if (
      filtros?.get('dataParalisacaoInicio') &&
      filtros?.get('dataParalisacaoInicio').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataParalisacaoInicio'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = this.addFilter(filter, `dataParalisacaoInicio=${validValue}`);
    }
    if (
      filtros?.get('dataReinicioInicio') &&
      filtros?.get('dataReinicioInicio').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataReinicioInicio'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = this.addFilter(filter, `dataReinicioInicio=${validValue}`);
    }
    if (
      filtros?.get('dataParalisacaoFim') &&
      filtros?.get('dataParalisacaoFim').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataParalisacaoFim'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = this.addFilter(filter, `dataParalisacaoFim=${validValue}`);
    }
    if (
      filtros?.get('dataReinicioFim') &&
      filtros?.get('dataReinicioFim').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataReinicioFim'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = this.addFilter(filter, `dataReinicioFim=${validValue}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { isParalisada, filtros, page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro =
        this.montaFilter(null, filtros) +
        `&exportType=${exportType}&apenasParalisadas=${isParalisada}`;

      window.open(
        `${apiURL}/api/obras/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderObrasParalisadas = obra => (
    <TableActions
      ref="row"
      key={
        'paralisada' +
        obra.get('idOrdemServico') +
        '_' +
        obra.get('exercicio') +
        '_' +
        obra.get('entidade') +
        '_' +
        obra.get('idOxy')
      }
      data={obra}
      onMore={this.onViewDetalhes}
    >
      <td>{obra.get('ordemServico') + '/' + obra.get('exercicio')}</td>
      <td>{obra.get('situacao')}</td>
      <td>{DateUtils.formatDateTimeShort(obra.get('data'))}</td>
      <td>{DateUtils.formatDateTimeShort(obra.get('previsaoTermino'))}</td>
      <td>{DateUtils.formatDateTimeShort(obra.get('acompData'))}</td>
      <td>{obra.get('acompMotivo')}</td>
      <td>
        {DateUtils.formatDateTimeShort(obra.get('acompPrevisaoReinicio'))}
      </td>
      <td>{obra.get('acompResponsavelNome')}</td>
      <td>{obra.get('empresa')}</td>
    </TableActions>
  );

  renderObrasGerais = obra => (
    <TableActions
      ref="row"
      key={
        'gerais' +
        obra.get('idOrdemServico') +
        '_' +
        obra.get('exercicio') +
        '_' +
        obra.get('entidade') +
        '_' +
        obra.get('idOxy')
      }
      data={obra}
      onMore={this.onViewDetalhes}
    >
      <td>{obra.get('ordemServico') + '/' + obra.get('exercicio')}</td>
      <td title={obra.get('descricao')}>
        <Ellipsis value={obra.get('descricao')} maxLength={160} />
      </td>
      <td>{obra.get('empresa')}</td>
      <td>{DateUtils.formatDateTimeShort(obra.get('data'))}</td>
      <td>{DateUtils.formatDateTimeShort(obra.get('previsaoTermino'))}</td>
      <td className="right">
        <NumberFormatter value={obra.get('valorIntervencao')} />
      </td>

      {!!obra.get('valorContrato') && obra.get('valorContrato') > 0 && (
        <td className="right">
          <Link
            onClick={this.showContrato}
            data={obra}
            value={NumberUtils.format(obra.get('valorContrato'))}
            icon="fa fa-external-link-square"
            iconPosition="right"
          />
        </td>
      )}
      {(!obra.get('valorContrato') || obra.get('valorContrato') <= 0) && (
        <td className="right">
          <NumberFormatter value={obra.get('valorContrato')} />
        </td>
      )}

      <td className="right">
        <NumberFormatter value={obra.get('valorAditivo')} />
      </td>
      <td>{obra.get('situacao')}</td>
      <td className="right">{NumberUtils.format(obra.get('percentual'))}%</td>
    </TableActions>
  );

  renderObras = obra => {
    const { isParalisada } = this.props;
    return isParalisada
      ? this.renderObrasParalisadas(obra)
      : this.renderObrasGerais(obra);
  };

  renderSituacao = item => {
    return (
      <option key={item.get('id')} value={item.get('id')}>
        {item.get('descricao')}
      </option>
    );
  };

  onViewDetalhes = item => {
    const { isIntegradoOxy, entidadeLogada } = this.props;
    const entidade = isIntegradoOxy ? item.get('entidade') : entidadeLogada;
    const exercicio = item.get('exercicio');
    const idOrdemServico = item.get('idOrdemServico');
    const idOxy = item.get('idOxy');

    this.props.history.push(
      idOxy
        ? `/obraspublicas/detalhes?idOxy=${idOxy}`
        : `/obraspublicas/detalhes?entidade=${entidade}&exercicioObra=${exercicio}&idOrdemServico=${idOrdemServico}`
    );
  };

  onConsulta = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroObras && this.refs.panelFiltroObras.collapse();
  };

  render() {
    const { page, situacoes, filtros, isParalisada } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel title="Obras Públicas" mobileClose ref="panelFiltroObras">
          <form>
            <Tabs ref="tabs" onTabClick={this.limparFiltros}>
              <Tab
                title="Consulta geral"
                name="obrasPublicas"
                ref="tabObrasPublicas"
                id="tabObrasPublicas"
                active
              >
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <div className={'form-group'}>
                      <label className="label">Situação</label>
                      <select
                        name="situacao"
                        key={filtros.get('situacao')}
                        className="form-control control-label"
                        ref="situacao"
                        type="select"
                        onChange={this.onChangeFiltro}
                        value={filtros.get('situacao')}
                      >
                        <option value=""> Selecione </option>
                        {situacoes.map(this.renderSituacao)}
                      </select>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="descricaoBem"
                      ref="descricaoBem"
                      label="Descrição do Bem"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('descricaoBem')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="descricaoObra"
                      ref="descricaoObra"
                      label="Descrição da Intervenção"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('descricaoObra')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="empresa"
                      ref="empresa"
                      label="Empresa"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('empresa')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <Input
                      name="ordemServico"
                      ref="ordemServico"
                      label="Ano de Intervenção"
                      type="number"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('ordemServico')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <Input
                      name="numeroIntervencao"
                      ref="numeroIntervencao"
                      label="Nº da Intervenção"
                      type="number"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('numeroIntervencao')}
                      defaultValue={0}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <FilterButtons
                      onConsulta={this.onConsulta}
                      onReset={this.limparFiltros}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab
                title="Obras paralisadas"
                name="obrasParalisadas"
                ref="tabObrasParalisadas"
                id="tabObrasParalisadas"
              >
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="descricaoBem"
                      ref="descricaoBem"
                      label="Descrição do Bem"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('descricaoBem')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="descricaoObra"
                      ref="descricaoObra"
                      label="Descrição da Intervenção"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('descricaoObra')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Input
                      name="empresa"
                      ref="empresa"
                      label="Empresa"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('empresa')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <Input
                      name="ordemServico"
                      ref="ordemServico"
                      label="Ano de Intervenção"
                      type="number"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('ordemServico')}
                      defaultValue={exercicio.id}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <Input
                      name="numeroIntervencao"
                      ref="numeroIntervencao"
                      label="Nº da Intervenção"
                      type="number"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('numeroIntervencao')}
                      defaultValue={0}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <DatePicker
                      id="dataParalisacaoInicio"
                      name="dataParalisacaoInicio"
                      ref="dataParalisacaoInicio"
                      label="Data Paralisação Mínima"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('dataParalisacaoInicio')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <DatePicker
                      id="dataParalisacaoFim"
                      name="dataParalisacaoFim"
                      ref="dataParalisacaoFim"
                      label="Data Paralisação Máxima"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('dataParalisacaoFim')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <DatePicker
                      id="dataReinicioInicio"
                      name="dataReinicioInicio"
                      ref="dataReinicioInicio"
                      label="Data Reinício Mínima"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('dataReinicioInicio')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    <DatePicker
                      id="dataReinicioFim"
                      name="dataReinicioFim"
                      ref="dataReinicioFim"
                      label="Data Reinício Máxima"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('dataReinicioFim')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <FilterButtons
                      onConsulta={this.onConsulta}
                      onReset={this.limparFiltros}
                    />
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </form>
        </Panel>

        <Panel expansible={false} isForm tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="25" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={page.get('content')?.isEmpty()}
                text={
                  'Não foi encontrado nenhuma Obra ' +
                  (isParalisada ? 'Paralisada ' : '') +
                  'para a pesquisa selecionada.'
                }
              >
                {isParalisada ? (
                  <tr>
                    <TableHeaderItem
                      field="ordemServico"
                      label="O.S/Ano"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="situacao"
                      label="Situação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="data"
                      label="Data Início"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="previsaoTermino"
                      label="Previsão Término"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="D.DATA"
                      label="Data Paralisação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="acompMotivo"
                      label="Motivo Paralisação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="D.DATAPREVISTAREINICIO"
                      label="Data Prev Reinício"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="E.NOME"
                      label="Fiscal Responsável"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="empresa"
                      label="Empresa"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <th></th>
                  </tr>
                ) : (
                  <tr>
                    <TableHeaderItem
                      field="ordemServico"
                      label="Intervenção/Ano"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="descricao"
                      label="Descrição da Intervenção"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="empresa"
                      label="Empresa"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="data"
                      label="Data Início"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="previsaoTermino"
                      label="Previsão Término"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="valorIntervencao"
                      label="Valor Intervenção"
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorContrato"
                      label="Valor Contrato"
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorAditivo"
                      label="Valor Aditivo"
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="situacao"
                      label="Situação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="percentual"
                      label="Medições"
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <th></th>
                  </tr>
                )}
              </TableHeader>
              <tbody>{page.get('content').map(this.renderObras)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.obra.get('obras'),
    situacoes: state.obra.get('situacoes'),
    filtros: state.crud.getIn(['filtroObras', 'currentRecord'], Map({})),
    isParalisada: state.obra.get('isParalisada'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ]),
    entidadeLogada: state.auth.getIn(['userInfo', 'entidade', 'id'])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(Obra);
