import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import AdiantamentoDetalheDocumentos from './AdiantamentoDetalheDocumentos.js';
import AdiantamentoDetalheAnexos from './AdiantamentoDetalheAnexos.js';
export class AdiantamentoDetalheInformacao extends Component {
  static propTypes = {
    adiantamento: PropTypes.object.isRequired,
    permiteExcederPrestacaoContas: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  render() {
    const {
      adiantamento,
      permiteExcederPrestacaoContas,
      downloadArquivo
    } = this.props;

    return (
      <div>
        {adiantamento && (
          <section>
            <CabecalhoEntidade />

            <BotaoImprimir />

            <Panel title="Detalhes do Adiantamento/Reembolso">
              <DisplayData border>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Prestação Contas"
                    value={
                      adiantamento.get('prestacaoContas') +
                      '/' +
                      adiantamento.get('exercicio')
                    }
                    id="prestacaoContas"
                  />
                  <DisplayDataItem
                    sm={6}
                    title="Data Prestação"
                    value={DateUtils.formatDateTimeShort(
                      adiantamento.get('dataPrestacao')
                    )}
                    id="dataPrestacao"
                  />
                </DisplayDataRow>

                <DisplayDataRow>
                  <DisplayDataItem
                    sm={6}
                    title="Empenho"
                    value={
                      <LinkToEmpenho
                        entidade={adiantamento.get('entidade')}
                        exercicio={adiantamento.get('exercicioEmpenho')}
                        empenho={adiantamento.get('empenho')}
                      />
                    }
                    id="empenho"
                  />
                  <DisplayDataItem
                    sm={6}
                    title="Data Empenho"
                    value={DateUtils.formatDateTimeShort(
                      adiantamento.get('dataEmpenho')
                    )}
                    id="dataEmpenho"
                  />
                </DisplayDataRow>

                <DisplayDataRow>
                  <DisplayDataItem
                    sm={4}
                    title="Nome"
                    value={adiantamento.get('nome')}
                    id="nome"
                  />
                  <DisplayDataItem
                    sm={4}
                    title="Cargo"
                    value={adiantamento.get('cargo')}
                    id="cargo"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Lotação"
                    value={adiantamento.get('lotacao')}
                    id="lotacao"
                  />
                  <DisplayDataItem
                    sm={2}
                    title="Matricula"
                    value={adiantamento.get('matricula')}
                    id="matricula"
                  />
                </DisplayDataRow>

                <DisplayDataRow>
                  <DisplayDataItem
                    sm={3}
                    title="Valor Pago"
                    value={NumberUtils.format(adiantamento.get('valorPago'))}
                    id="valorPago"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Valor Empenho"
                    value={NumberUtils.format(adiantamento.get('valorEmpenho'))}
                    id="valorEmpenho"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Valor Documentos"
                    value={NumberUtils.format(
                      adiantamento.get('valorDocumentos')
                    )}
                    id="valorDocumentos"
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Valor Restituido"
                    value={NumberUtils.format(
                      adiantamento.get('valorRestituido')
                    )}
                    id="valorRestituido"
                  />
                </DisplayDataRow>

                {permiteExcederPrestacaoContas &&
                  permiteExcederPrestacaoContas == 'S' && (
                    <DisplayDataRow>
                      <DisplayDataItem
                        sm={6}
                        title="Empenho Valor Excedido"
                        value={
                          <LinkToEmpenho
                            entidade={adiantamento.get('entidade')}
                            exercicio={adiantamento.get(
                              'exercicioEmpenhoExcedido'
                            )}
                            empenho={adiantamento.get('empenhoExcedido')}
                          />
                        }
                        id="empenhoValorExcedido"
                      />
                      <DisplayDataItem
                        sm={3}
                        title="Valor Excedido"
                        value={NumberUtils.format(
                          adiantamento.get('valorExcedido')
                        )}
                        id="valorExcedido"
                      />
                    </DisplayDataRow>
                  )}
              </DisplayData>
              <hr className="mt-xs mb-xs" />

              {(!!adiantamento.get('anexos')?.length ||
                !!adiantamento.get('documentos')?.length) && (
                <Tabs id="tabs">
                  <Tab
                    onlyTable
                    tableResponsive
                    id="documentos"
                    title="Documentos"
                    active={true}
                  >
                    <AdiantamentoDetalheDocumentos
                      documentos={adiantamento.get('documentos')}
                    />
                  </Tab>
                  <Tab onlyTable tableResponsive id="anexos" title="Anexos">
                    {adiantamento.get('anexos') && (
                      <AdiantamentoDetalheAnexos
                        anexos={adiantamento.get('anexos')}
                        downloadArquivo={downloadArquivo}
                      />
                    )}
                  </Tab>
                </Tabs>
              )}
            </Panel>
          </section>
        )}
      </div>
    );
  }
}

export default AdiantamentoDetalheInformacao;
