import Modal from 'portaltransparencia-common/components/Modal.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import GastoAbastecimentoTable from './GastoAbastecimentoTable.react';

export class GastoAbastecimentoModal extends Component {
  static propTypes = {
    gastosAbastecimento: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    idEntidade: PropTypes.any
  };

  render() {
    const { gastosAbastecimento, show, onClose, idEntidade } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <GastoAbastecimentoTable
            gastosAbastecimento={gastosAbastecimento}
            idEntidade={idEntidade}
          />
        </Modal>
      );
    }

    return null;
  }
}

export default GastoAbastecimentoModal;
