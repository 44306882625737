export const isIOSDevice = () => {
  return /iPhone|iPad|iPod/.test(navigator.userAgent);
};

export const downloadBasedOnPlatform = url => {
  if (isIOSDevice()) {
    const a = document.createElement('a');
    a.href = url;
    a.download = url;
    a.click();
  } else {
    window.open(url);
  }
};
