import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';

export class AditivoDetalheInformacao extends Component {
  static propTypes = {
    aditivo: PropTypes.object.isRequired
  };

  render() {
    const { aditivo } = this.props;

    return (
      <Panel title={'Aditivo'}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Número Aditivo"
              value={aditivo.get('numeroAditivo')}
              id="numeroAditivo"
            />
            <DisplayDataItem
              sm={6}
              title="Data"
              value={DateUtils.transform(aditivo.get('data'))}
              id="data"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Tipo do Aditivo"
              value={aditivo.get('tipoAditivoDescricao')}
              id="tipoAditivoDescricao"
            />
            <DisplayDataItem
              sm={6}
              title="Tipo de Operação"
              value={aditivo.get('tipoOperacaoDescricao')}
              id="tipoOperacaoDescricao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Data Publicação"
              value={DateUtils.transform(aditivo.get('dataPublicacao'))}
              id="dataPublicacao"
            />
            <DisplayDataItem
              sm={6}
              title="Data Término"
              value={DateUtils.transform(aditivo.get('dataTermino'))}
              id="dataTermino"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Valor Aditivo"
              value={NumberUtils.format(
                aditivo.get('valorAditivo'),
                'R$ 0,0.00'
              )}
              id="valorAditivo"
            />
            <DisplayDataItem
              sm={6}
              title="Motivação"
              value={aditivo.get('motivacao')}
              id="motivacao"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default AditivoDetalheInformacao;
