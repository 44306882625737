import { Map } from 'immutable';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import TreeTable from 'portaltransparencia-common/components/treetable/TreeTable.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_EM_LIQUIDACAO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import FormDespesaNivel from './FormFiltroNivel.react.js';
import TableItem from './TableItem.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import FormDiaFiltro from './FormDiaFiltro.react.js';

export class FormDespesaDetalhada extends Component {
  static propTypes = {
    findDespesas: PropTypes.func.isRequired,
    despesas: PropTypes.object.isRequired,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    paramEmLiquidacoes: PropTypes.string,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetDespesas(this.props);
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { despesas, addErrorNotification } = this.props;

    let filtro = this.montarFiltro();

    if (!despesas.isEmpty()) {
      window.open(
        `${apiURL}/despesapornivel/reportNivel?${filtro}$entidade=${entidade.id}&exercicio=${exercicio.id}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderItem = item => {
    const { expand, collapse, paramEmLiquidacoes } = this.props;

    return (
      <TableItem
        key={item.get('programatica')}
        item={item}
        expand={expand}
        collapse={collapse}
        paramEmLiquidacoes={paramEmLiquidacoes}
      />
    );
  };

  render() {
    const { despesas, paramEmLiquidacoes, onChange, filtros } = this.props;

    const header = (
      <TableHeader empty={despesas.isEmpty()}>
        <tr>
          <th>Programática</th>
          <th>Descrição</th>
          <th className="right">Dotação Inicial</th>
          <th className="right">Créditos Adicionais</th>
          <th className="right">Dotação Atualizada</th>
          <th className="right">Valor Empenhado</th>
          {paramEmLiquidacoes == 'S' && (
            <th className="right">Em Liquidação</th>
          )}
          <th className="right">Valor Liquidado</th>
          <th className="right">Valor Pago</th>
        </tr>
      </TableHeader>
    );

    const filtroDespesas = 'filtroDespesas';

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <FormDiaFiltro
          onChange={onChange}
          filtros={filtros}
          montarFiltro={this.montarFiltro}
          resetCrud={resetCrud}
          filtroDespesas={filtroDespesas}
          onConsulta={this.onConsulta}
          location={this.props.location}
          onResetDespesas={this.onResetDespesas}
        />

        <Panel isTable tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="despesa"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6} />
          </Row>
          <Tabs ref="tab">
            <Tab title="Árvore das Despesas" name="arvore" onlyTable active>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
              <TreeTable
                list={despesas}
                header={header}
                itemRenderer={this.renderItem}
              />
            </Tab>
            <Tab title="Despesas por Nível" name="nivel" onlyTable>
              <FormDespesaNivel
                location={this.props.location}
                montarFiltro={this.montarFiltro}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }

  montarFiltro = (filtroInicial = '') => {
    const { filtros } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    let filter = '';

    if (filtroInicial) {
      filter += filtroInicial;
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${encodeURIComponent(
          DateUtils.transform(
            filtros.get('dataInicial'),
            'yy-mm-dd',
            'yy-mm-dd'
          )
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `dataInicial=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-01-01`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(
        filter,
        `dataFinal=${encodeURIComponent(
          DateUtils.transform(filtros.get('dataFinal'), 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `dataFinal=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-12-31`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    return filter;
  };

  onConsulta = () => {
    const { findDespesas } = this.props;
    let filter = this.montarFiltro();
    findDespesas(filter);
  };

  onResetDespesas = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    const { findDespesas, onChange } = this.props;

    onChange('filtroDespesas', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });
    onChange('filtroDespesas', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    let filtroDateIni = `&dataInicial=${exercicio.id}-01-01&dataFinal=${exercicio.id}-12-31`;

    findDespesas(this.montarFiltro(filtroDateIni));
  };
}

function mapStateToProps(state) {
  return {
    despesas: state.despesasDetalhe.get('despesas'),
    filtros: state.crud.getIn(['filtroDespesas', 'currentRecord'], Map({})),
    paramEmLiquidacoes: ParametroService.getValorParametro(
      state,
      PARAM_EM_LIQUIDACAO
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormDespesaDetalhada);
